<template>
  <div>
    <b-overlay :show="isLoading">
      <b-card>
        <template #header>
          <h4 class="card-title">
            ProActive Checks
          </h4>
          <b-button
            v-if="!todayCheckup.id && isHost"
            size="sm"
            variant="primary"
            @click="createCheck"
          >
            Create check
          </b-button>
          <b-button
            v-if="todayCheckup.id && isHost"
            size="sm"
            variant="primary"
            @click="updateCheck"
          >
            Update check of today
          </b-button>
        </template>

        <AssetCheckupList
          :checkups="checkups"
        />

        <b-pagination
          v-model="currentPage"
          class="mt-2"
          :total-rows="totalDataCount"
          align="right"
          :per-page="dataPerPage"
        />
      </b-card>
    </b-overlay>

    <DailyCheckModal
      ref="dailyCheckModal"
      @dailyCheckupUpdate="loadData"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BCard, BPagination, BOverlay, VBTooltip, BButton,
} from 'bootstrap-vue'

import AssetCheckupList from '@/components/dailycheck/AssetCheckupList.vue'
import DailyCheckModal from '@/components/dailycheck/DailyCheckModal.vue'
import DailyCheckupService from '@/service/dailycheckup.service'

export default {
  components: {
    BCard,
    BPagination,
    BOverlay,
    DailyCheckModal,
    AssetCheckupList,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      checkups: [],
      todayCheckup: {},
      totalDataCount: 0,
      dataPerPage: 15,
      currentPage: 1,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    requestSkipCount() {
      return this.dataPerPage * (this.currentPage - 1)
    },
  },
  watch: {
    currentPage() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    createCheck() {
      this.isLoading = true
      DailyCheckupService.createAsync({ assetId: this.asset.id, status: 0 }, { disableTenantFilter: true }) // Create checkup
        .finally(() => {
          this.isLoading = false
          this.loadData()
        })
    },
    updateCheck() {
      this.$refs.dailyCheckModal.show(this.asset.id)
    },
    loadData() {
      this.isLoading = true

      const today = this.$moment()
      DailyCheckupService.getListAsync({
        assetId: this.asset.id,
        sorting: 'CheckedOn desc',
        maxResultCount: this.dataPerPage,
        skipCount: this.requestSkipCount,
      }, { disableTenantFilter: true })
        .then(result => {
          this.checkups = result.items
          this.totalDataCount = result.totalCount

          if (this.checkups.length > 0) {
            const lastCheckupDate = this.checkups[0].checkedOn
            if (this.$moment(lastCheckupDate).isSame(today, 'd')) {
              [this.todayCheckup] = this.checkups
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
